import { RefObject, SyntheticEvent, useState } from 'react';
import { FormattedMessage as T, useIntl } from 'react-intl';
import { Form, Box, Button, InputField, ErrorMessage } from '@otovo/rainbow';
import sharedMessages from '@otovo/shared/components/Translations/messages';
import m from './messages';
import { generateOtpCode, ERROR_CODE } from './api';

function getErrorMessage(message: string) {
  switch (message) {
    case ERROR_CODE.INVALID_EMAIL:
      return m.invalidEmail;

    case ERROR_CODE.TOO_MANY_ATTEMPTS:
      return m.tooManyAttempts;

    default:
      return m.networkError;
  }
}

function isValidEmail(email: string) {
  // Disclaimer: Stolen from @rootisenabled's implementation in the App
  // Of course, this regex doesn't work in 100% of the cases. But it's effective
  // enough to filter out the most obvious mistakes. The Cloud API will actually
  // validate the email and match it against the user database.
  const regex = /[^@]+@[^.]+..+/g;
  return regex.test(email);
}

type Props = {
  onNext: (medium: 'sms' | 'email') => void;
  email: string;
  setEmail: (email: string) => void;
  inputRef: RefObject<InputField>;
};

const EnterEmailForm = ({ onNext, email, setEmail, inputRef }: Props) => {
  const intl = useIntl();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const sendCode = async (event: SyntheticEvent<HTMLInputElement>) => {
    event.preventDefault();
    setError('');

    const trimmedEmail = email.trim().toLowerCase();

    if (!isValidEmail(trimmedEmail)) {
      setError(intl.formatMessage(m.invalidEmail));
      return;
    }

    setLoading(true);

    try {
      const { medium } = await generateOtpCode(trimmedEmail);
      setLoading(false);
      onNext(medium);
    } catch (err) {
      setLoading(false);
      setError(intl.formatMessage(getErrorMessage(err.message)));
    }
  };

  const inputChanged = (event: SyntheticEvent<HTMLInputElement>) => {
    setError('');
    setEmail(event.currentTarget.value);
  };

  return (
    <Form noValidate onSubmit={sendCode}>
      <InputField
        ref={inputRef}
        id="login-email"
        type="email"
        autoComplete="email"
        label={intl.formatMessage(sharedMessages.email)}
        textAlign="left"
        name="email"
        value={email}
        required
        onChange={inputChanged}
        validationState={error ? 'error' : ''}
      />
      {error && (
        <ErrorMessage
          mt="2"
          backgroundColor={{
            s: 'red_5',
            m: 'transparent',
          }}
          py="1"
          px="1"
          borderRadius="2"
        >
          {error}
        </ErrorMessage>
      )}
      <Box mt="5">
        <Button loading={loading} type="submit">
          <T {...m.sendCode} />
        </Button>
      </Box>
    </Form>
  );
};

export default EnterEmailForm;
